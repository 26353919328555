@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css);
body {
  font-family: 'Roboto', sans-serif !important;
  background-color: #f8f9fa !important; }

.main-panel {
  width: 100%;
  height: 100vh; }

.bg-white {
  background: #fff !important; }

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  font-size: 18px; }

.p-main-box {
  max-width: 10rem;
  max-height: 10rem; }
  .p-main-box .p-img {
    width: 4rem;
    height: 4rem;
    border-radius: 12px; }

.P-T-Name {
  width: 15rem; }

.inner-p {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .inner-p .card {
    width: 8rem;
    height: 8rem;
    margin-bottom: 20px;
    cursor: pointer !important; }
  .inner-p .card-body {
    flex: 1 1 auto;
    padding: 0.5rem 0.5rem; }

.table th {
  white-space: nowrap; }

.table td,
.table th {
  font-size: 14px !important;
  vertical-align: middle; }

span.action-min {
  display: inline-flex;
  width: 45px;
  height: 45px;
  background: #f4f5f6;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #42526a; }

span.action-plus {
  display: inline-flex;
  width: 45px;
  height: 45px;
  background: #f4f5f6;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #42526a; }

.action-del {
  display: inline-flex;
  width: 45px;
  height: 45px;
  background: #ed5575;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: #ffffff; }

.footer-fix {
  position: absolute;
  bottom: 5px;
  width: 100%; }

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  cursor: pointer !important; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #fff;
  border-right: 1px solid #506070; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #506070;
  border-radius: 5px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #506070; }

/* Handle on hover */
::-moz-scrollbar-thumb:hover {
  background: #a79f9f; }

/* width */
::-moz-scrollbar {
  width: 3px; }

/* Track */
::-moz-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-right: 2px solid #506070; }

/* Handle */
::-moz-scrollbar-thumb {
  background: #E93329;
  border-radius: 10px; }

* {
  scrollbar-width: thin;
  scrollbar-color: #506070 #ffff; }

.text-clr-grey {
  color: #2c3542; }

.master-login {
  height: 100%;
  position: absolute;
  width: 100%;
  background: linear-gradient(226deg, #506070 0%, #2c3542 100%); }
  .master-login .logo-text {
    font-size: 30px;
    color: #f2484b;
    text-transform: capitalize !important;
    font-weight: 500; }
    .master-login .logo-text .food {
      color: #9bb353; }
  .master-login .master-content {
    background-color: rgba(255, 255, 255, 0.769);
    border-radius: 7px;
    width: 400px;
    margin: 0px auto;
    overflow: hidden;
    z-index: 12;
    position: relative; }
    .master-login .master-content .error-message {
      text-transform: capitalize;
      line-height: 10px;
      font-size: 12px;
      font-weight: 300;
      background-image: linear-gradient(180deg, #F44336 0%, #B71C1C 100%); }
    .master-login .master-content .login-label {
      position: absolute;
      left: 25px;
      color: #8a8a8a; }
    .master-login .master-content .login-input {
      border: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background-color: #E8EAF4 !important;
      padding-left: 0; }
    .master-login .master-content .login-input:focus {
      box-shadow: none; }
    .master-login .master-content .span-user {
      border: 0;
      background: #e8eaf4;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
    .master-login .master-content .view-password {
      position: absolute;
      right: 0;
      top: 16px; }
    .master-login .master-content .form-actions .btn {
      font-weight: 500;
      padding: 8px 18px;
      border-radius: 25px;
      background: #ffc119;
      color: #2c3542;
      border-color: #ffc119; }
    .master-login .master-content .rememberme {
      display: inline-block;
      position: relative;
      cursor: pointer !important;
      font-size: 14px;
      transition: all .3s;
      padding-left: 18px;
      color: #8a8a8a; }
      .master-login .master-content .rememberme input {
        accent-color: #ffc119;
        position: absolute;
        top: 3px;
        left: 0; }
    .master-login .master-content .forget-password {
      font-size: 14px;
      color: #8a8a8a;
      cursor: pointer !important; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important; }

.area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh; }

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: animate 30s linear infinite;
          animation: animate 30s linear infinite;
  bottom: -150px; }

.circles li:nth-child(1) {
  left: 25%;
  width: 170px;
  height: 170px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

.circles li:nth-child(2) {
  left: 10%;
  width: 130px;
  height: 130px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s; }

.circles li:nth-child(3) {
  left: 70%;
  width: 50px;
  height: 50px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s; }

.circles li:nth-child(4) {
  left: 40%;
  width: 90px;
  height: 90px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s; }

.circles li:nth-child(5) {
  left: 65%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s; }

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s; }

.circles li:nth-child(8) {
  left: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s; }

.circles li:nth-child(9) {
  left: 20%;
  width: 40px;
  height: 40px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s; }

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s; }

li.circle-spin {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }

/* .circle-spin {
    display: flex;
    justify-content: center;
  } */
.circle-img {
  width: 60%; }

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 50%; }
  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%; } }

@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 50%; }
  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%; } }

.loading-img {
  display: block !important;
  margin: 8% auto !important; }

.toast-order {
  position: absolute;
  right: 45px;
  bottom: 100px;
  width: 318px !important;
  z-index: 11;
  text-transform: capitalize; }

.toast-box-one {
  background: #212529a8 !important;
  border: none !important;
  border-bottom-right-radius: 0px !important;
  color: white; }

.toast-box-one.arrow-bottom-right::before {
  content: " ";
  position: absolute;
  right: 4px;
  bottom: -17px;
  border-top: 17px solid #6d6f72;
  border-right: 0px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: none; }

.toast-info {
  position: absolute;
  right: 5px;
  bottom: 100px;
  width: 290px !important;
  z-index: 11;
  text-transform: capitalize; }

.toast-box-two {
  background: #212529a8 !important;
  border-bottom-left-radius: 0px !important;
  border: none !important;
  color: white; }

.toast-box-two.arrow-bottom-left::before {
  content: " ";
  position: absolute;
  left: 4px;
  bottom: -16px;
  border-top: 16px solid #6d6f72;
  border-right: 13px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: none; }

.toast-orderdetails {
  position: absolute;
  right: 200px;
  bottom: 90px;
  width: 318px !important;
  z-index: 11;
  text-transform: capitalize; }

.toast-box-orderdetails {
  background: #212529a8 !important;
  border: none !important;
  border-bottom-right-radius: 0px !important;
  color: white; }

.toast-box-orderdetails.arrow-bottom-right::before {
  content: " ";
  position: absolute;
  right: 4px;
  bottom: -17px;
  border-top: 17px solid #6d6f72;
  border-right: 0px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: none; }

.badge.bg-light-red {
  background: #ed5575 !important;
  border-color: #ed5575 !important; }

.badge.bg-light-green {
  background: #46a846 !important;
  border-color: #46a846 !important; }

.bg-light-red {
  background: #ed5575 !important;
  border-color: #ed5575 !important; }

.bg-light-green {
  background: #46a846 !important;
  border-color: #46a846 !important; }

.bg-light-blue {
  background: #506070 !important;
  border-color: #506070 !important; }

.bg-light-grey {
  background: #c2c2c2 !important;
  border-color: #c2c2c2 !important; }

.summary-bg-red {
  background: linear-gradient(180deg, #ed5575 0%, #ed5575ab 100%) !important; }

.summary-bg-green {
  background: linear-gradient(180deg, #46a846 0%, #46a846b8 100%) !important; }

.summary-bg-yellow {
  background: linear-gradient(180deg, #b2500b 0%, #d39c56 100%) !important; }

.summary-card {
  background: linear-gradient(180deg, #008CBA 0%, #6fadcb 100%);
  border: none !important; }
  .summary-card .summary-box {
    padding: 6px 2px;
    border-radius: 4px;
    min-height: 65px; }
  .summary-card .label-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffc107; }
  .summary-card .value-text {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    z-index: 999;
    top: 22px; }

.view-password {
  position: absolute;
  right: 0;
  top: 16px; }

.toast-manager {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 310px !important;
  z-index: 11;
  text-transform: capitalize; }

.toast-box-manager {
  background: #212529a8 !important;
  border: none !important;
  color: white; }

.form-control.is-invalid {
  background-image: none !important; }

.day-height {
  height: calc(3.5rem + 2px);
  line-height: 1.25; }

.m-height {
  overflow: auto;
  height: 392px; }

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer !important;
  height: auto;
  width: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1; }

.date-icon {
  position: absolute;
  right: 18px;
  bottom: 9px;
  cursor: pointer !important;
  color: #8a9396; }

.text-light-blue {
  color: #8a9396 !important; }

.text-light-blue:hover {
  color: #455A64 !important; }

.text-light-red {
  color: #ed5575 !important; }

.text-light-green {
  color: #46a846 !important; }

.badge.bg-light-clr {
  background: #97d35e !important; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #506070 !important; }

.shadow-inset {
  box-shadow: inset 0 0 2px #000000; }

.fs-14 {
  font-size: 14px !important; }

input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important; }

.form-input {
  text-indent: 25px; }

.card-height {
  max-height: 130px;
  overflow-y: auto; }

.select-p {
  width: unset !important; }

.fs-12 {
  font-size: 12px !important; }

/* .navbar-light .navbar-toggler {
    color: rgb(255 255 255 / 55%);
    border-color: rgb(255 255 255 / 10%);
} */

.nav-modal-btn {
  font-size: 14px;
  font-weight: 700;
  color: #1d3356 !important;
}
.nav-pills .nav-link.active {
  color: #fff !important;
  background-color: #2196F3;
  border-radius: 0px;
}
.nav-pills .nav-link:focus, .nav-link:hover{
  border-radius: 0px;
}
